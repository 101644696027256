<template lang="pug">
div.d-flex.flex-column.test-result-container
  v-card.flex-grow-1.ma-2(v-if="testResult")
    v-card-title.d-flex.align-center
      span Résultat du test
      v-spacer
      v-chip(
        v-if="isSavedResult"
        color="success"
        small
        outlined
      )
        v-icon(left small) mdi-content-save
        | Résultat sauvegardé
    v-card-text.flex-grow-1.overflow-auto.result-container
      // Affichage formaté des résultats
      div.mb-4(v-if="hasResults")
        h3.mb-2.d-flex.align-center
          span Résultats de la catégorisation
          v-icon.ml-2(
            v-if="mismatchedResults.length > 0"
            color="error"
            small
          ) mdi-alert-circle
          v-icon.ml-2(
            v-else
            color="success"
            small
          ) mdi-check-circle
          span.ml-2.caption(
            :class="mismatchedResults.length > 0 ? 'error--text' : 'success--text'"
          ) {{ mismatchedResults.length > 0 ? `${mismatchedResults.length} erreur(s)` : 'Tout correspond' }}
        v-list.pa-0.result-list
          // D'abord afficher les résultats qui ne correspondent pas
          template(v-for="(result, index) in mismatchedResults")
            v-list-item(:key="'mismatch-' + index" dense)
              v-list-item-content
                v-list-item-title.error--text
                  | {{ result.packageName }} - {{ result.category }}
                v-list-item-subtitle.error--text.font-italic
                  | Expected category: {{ result.expectedCategory }}
          
          // Ensuite afficher les résultats qui correspondent
          template(v-for="(result, index) in matchedResults")
            v-list-item(:key="'match-' + index" dense)
              v-list-item-content
                v-list-item-title.success--text
                  | {{ result.packageName }} - {{ result.category }}
      
      // Message si aucun résultat formaté n'est disponible
      v-alert(
        v-if="!hasResults && testResult"
        type="info"
        text
        class="mb-4"
      ) Aucun résultat formaté disponible. Consultez le résultat brut ci-dessous.
      
      // Affichage du résultat brut JSON
      div.mt-4
        h3.mb-2 Résultat brut
        pre.json-result {{ testResult }}
  
  v-card.flex-grow-1.ma-2.d-flex.align-center.justify-center(v-else)
    v-card-text.text-center.grey--text
      v-icon(size="64") mdi-code-json
      div.mt-2 Le résultat du test s'affichera ici
  
  v-card.ma-2
    v-card-actions
      v-btn(
        color="primary"
        @click="$emit('test-prompt')"
        :loading="testLoading"
        block
      ) Tester
</template>

<script>
export default {
  name: 'PromptTestResult',
  props: {
    testResult: {
      type: [Object, null],
      default: null
    },
    testLoading: {
      type: Boolean,
      default: false
    },
    isSavedResult: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedResults() {
      // Vérifier différentes structures possibles de résultat
      if (!this.testResult) {
        return [];
      }
      
      // Vérifier si les résultats sont dans testResult.results
      if (this.testResult.results && Array.isArray(this.testResult.results)) {
        return this.testResult.results;
      }
      
      // Vérifier si les résultats sont dans testResult.resultList
      if (this.testResult.resultList && Array.isArray(this.testResult.resultList)) {
        return this.testResult.resultList.map(item => ({
          packageName: item.result?.packageName || 'Inconnu',
          category: item.result?.category || 'Inconnu',
          expectedCategory: item.expectedCategory
        }));
      }
      
      // Si le testResult est lui-même un tableau
      if (Array.isArray(this.testResult)) {
        return this.testResult;
      }
      
      return [];
    },
    
    hasResults() {
      return this.formattedResults && this.formattedResults.length > 0;
    },
    
    // Résultats qui ne correspondent pas aux catégories attendues
    mismatchedResults() {
      return this.formattedResults.filter(result => 
        result.expectedCategory && result.category !== result.expectedCategory
      );
    },
    
    // Résultats qui correspondent aux catégories attendues
    matchedResults() {
      return this.formattedResults.filter(result => 
        !result.expectedCategory || result.category === result.expectedCategory
      );
    }
  }
}
</script>

<style scoped>
.test-result-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.json-result {
  background-color: #ffffff;
  color: #000000;
  padding: 16px;
  border-radius: 4px;
  font-family: monospace;
  white-space: pre-wrap;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: auto;
  max-height: 300px;
}

.result-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto !important;
  overflow-x: hidden;
  height: 100%;
  max-height: calc(100vh - 200px);
}

.result-list {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 8px !important;
  margin-bottom: 16px;
  max-height: 300px;
  overflow-y: auto;
}

/* Assurer que les cartes prennent l'espace disponible */
.test-result-container > .v-card.flex-grow-1 {
  flex: 1 1 auto;
  min-height: 0;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Assurer que le contenu de la carte s'étend correctement */
.test-result-container > .v-card.flex-grow-1 > .v-card__text {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 0;
}

/* Style pour les éléments de liste */
.v-list-item {
  min-height: 36px !important;
}

.font-italic {
  font-style: italic;
}

/* Assurer que le texte d'erreur est bien visible */
.error--text {
  color: #f44336 !important;
}

/* Assurer que le texte de succès est bien visible */
.success--text {
  color: #4caf50 !important;
}
</style> 