<template lang="pug">
v-card.flex-grow-1.d-flex.flex-column.ma-2
  v-card-title
    div.d-flex.align-center.w-100.header-prompt-container
      span.mr-auto Gestion des prompts
      div.d-flex.align-center
        v-select(
          v-model="localSelectedPromptId"
          :items="promptList"
          item-text="displayText"
          item-value="id"
          label="Sélectionner un prompt"
          outlined
          dense
          hide-details
          style="max-width: 300px"
          :loading="promptsLoading"
          @change="onPromptChange"
          clearable
          :disabled="promptList.length === 0"
        )
          template(v-slot:selection="{ item }")
            div
              v-icon(v-if="item.id === activePromptId" color="success" small left) mdi-check-circle
              | {{ item.displayText }}
          template(v-slot:item="{ item }")
            div
              v-icon(v-if="item.id === activePromptId" color="success" small left) mdi-check-circle
              | {{ item.displayText }}
        v-btn(
          icon
          class="ml-2"
          @click="$emit('open-prompts-manager')"
          :disabled="promptList.length === 0"
        )
          v-icon mdi-cog
  v-card-text.flex-grow-1.d-flex.flex-column
    v-textarea(
      :value="prompt"
      @input="$emit('update:prompt', $event)"
      label="Prompt"
      outlined
      no-resize
      hide-details="auto"
      class="prompt-editor flex-grow-1"
      style="height: 100%"
      :key="'prompt-editor-' + (selectedPromptId || 'new')"
      ref="promptTextarea"
    )
  v-card-actions
    v-btn(
      v-if="selectedPromptId && !isActivePrompt"
      color="warning"
      @click="$emit('set-active-prompt')"
      :loading="setActiveLoading"
      class="mr-2"
    ) 
      v-icon(left) mdi-star
      | Définir comme actif
    v-spacer
    v-btn(
      v-if="selectedPromptId && promptList.length > 0"
      color="info"
      @click="$emit('create-from-template')"
      class="mr-2"
    ) Créer à partir de ce modèle
    v-btn(
      color="success"
      @click="$emit('confirm-save')"
      :loading="saveLoading"
    ) {{ isEditMode ? 'Sauvegarder' : 'Créer' }}
</template>

<script>
export default {
  name: 'PromptEditor',
  props: {
    prompt: {
      type: String,
      required: true
    },
    selectedPromptId: {
      type: [String, Number, null],
      default: null
    },
    promptList: {
      type: Array,
      required: true
    },
    promptsLoading: {
      type: Boolean,
      default: false
    },
    activePromptId: {
      type: [String, Number, null],
      default: null
    },
    isActivePrompt: {
      type: Boolean,
      default: false
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    saveLoading: {
      type: Boolean,
      default: false
    },
    setActiveLoading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    selectedPromptId() {
      // Quand le prompt sélectionné change, attendre que le DOM soit mis à jour
      this.$nextTick(() => {
        // Puis mettre le focus sur le textarea
        this.focusTextarea();
      });
    }
  },
  computed: {
    localSelectedPromptId: {
      get() {
        return this.selectedPromptId;
      },
      set(value) {
        this.$emit('prompt-selected', value);
      }
    }
  },
  methods: {
    onPromptSelected() {
      this.$emit('prompt-selected');
    },
    
    onPromptChange(value) {
      console.log('Prompt changé dans le composant enfant:', value);
      this.$emit('prompt-selected', value);
    },
    
    updatePrompt(value) {
      this.$emit('update:prompt', String(value));
    },
    
    focusTextarea() {
      // Attendre un court délai pour s'assurer que le contenu est chargé
      setTimeout(() => {
        // Trouver le textarea et lui donner le focus
        if (this.$refs.promptTextarea) {
          const textarea = this.$refs.promptTextarea.$el.querySelector('textarea');
          if (textarea) {
            // Donner le focus au textarea
            textarea.focus();
            
            // Positionner le curseur au début du texte
            textarea.setSelectionRange(0, 0);
            
            // Faire défiler vers le haut
            textarea.scrollTop = 0;
          }
        }
      }, 50);
    }
  }
}
</script>

<style scoped>
.prompt-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-prompt-container {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.prompt-editor :deep(.v-input__control) {
  font-family: monospace;
  line-height: 1.5;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.prompt-editor :deep(.v-input__slot) {
  flex-grow: 1;
  height: 100%;
  display: flex;
}

.prompt-editor :deep(.v-text-field__slot) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.prompt-editor :deep(.v-text-field__slot textarea) {
  white-space: pre-wrap;
  font-family: monospace;
  line-height: 1.5;
  flex-grow: 1;
  height: 100% !important;
  max-height: none !important;
  overflow-y: auto !important;
}
</style> 