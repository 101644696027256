
import Vue from 'vue';
import {EScreenTimeStoreAttribute, screenTimeStore} from '@/store/screen.time.store';
import {INSnapshotRecap} from '@/interface/n.snapshot.recap.interface';
import withSnapshotCard from '@/components/screen-time/with-snapshot-card.vue';
import screenshotsChart from '@/components/screen-time/screenshots-chart.vue';

export default Vue.extend({
  data: () => ({
    duration: '',
    nDays: 15,
    mounted: false,
    componentKey: 0,
  }),
  components: {
    withSnapshotCard,
    screenshotsChart,
  },
  async mounted() {
    await this.getDataFromApi();
    this.mounted = true;
  },
  computed: {
    nSnapshotRecap(): INSnapshotRecap {
      return screenTimeStore.state[EScreenTimeStoreAttribute.N_SNAPSHOT_RECAP];
    },
    nTotalSnapshotPerDayValues() {
      return this.nSnapshotPerDayValues.map((value) => value.total).reverse();
    },
    nLonelySnapshotPerDayValues() {
      return this.nSnapshotPerDayValues.map((value) => value.lonely).reverse();
    },
    nWithAttendeeSnapshotPerDayValues() {
      return this.nSnapshotPerDayValues.map((value) => value.withAttendee).reverse();
    },
    withAttendeeSnapshotDetailsPerDayValues() {
      return this.nSnapshotPerDayValues.map((value) => value.withAttendeeDetails).reverse();
    },
    lonelySnapshotDetailsPerDayValues() {
      return this.nSnapshotPerDayValues.map((value) => value.lonelyDetails).reverse();
    },
    nSnapshotPerDayLabel(): string[] {
      let labelList = Object.keys(
        screenTimeStore.state[EScreenTimeStoreAttribute.N_SNAPSHOT_PER_DAY],
      );
      labelList = labelList.map((label) => {
        const newLabel = new Date(label).toLocaleDateString().substring(0, 5);
        return newLabel;
      });
      return labelList.reverse();
    },
    nSnapshotPerDayValues() {
      return Object.values(
        screenTimeStore.state[EScreenTimeStoreAttribute.N_SNAPSHOT_PER_DAY],
      );
    },
  },
  watch: {
    async duration() {
      if (this.duration === '15 last days') {
        this.nDays = 15;
      } else if (this.duration === 'all') {
        this.nDays = -1;
      }
      await this.getDataFromApi();
      this.forceRerender();
    },
  },
  filters: {
  },
  methods: {
    async getDataFromApi() {
      // await this.$store.dispatch('getSnapshotRecap');
      // await this.$store.dispatch('getNSnapshotPerDay', {nDays: this.nDays});
      console.log('getDataFromApi');
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
});
