import {IAttendeeUser} from '@/interface/attendee.user.interface';
import {IContext} from '@/store/interface/context.interface';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {AttendeeUserApiService} from '@/service/api/user/attendeeUser/attendee.user.api.service';
import {IAttendeeUserUpdateDto} from '@/service/api/user/attendeeUser/dto/attendee.user.update.dto';
import {
  IUpdateAttendeeUserMediationDto,
} from '@/service/api/user/attendeeUser/dto/update.attendee.user.mediation.dto';
import {
  IAcceptAttendeeUserMediationStepDto,
} from '@/service/api/user/attendeeUser/dto/accept.attendee.user.mediation.step.dto';
import {
  IUpdateAttendeeUserPresetDto,
} from '@/service/api/user/attendeeUser/dto/update.attendee.user.preset.dto';
import {
  IUpdateAttendeeUserRestPeriodDto,
} from '@/service/api/user/attendeeUser/dto/update.attendee.user.rest.period.dto';

export enum EAttendeeUserStoreAttribute {
  ATTENDEE_USER_LIST = 'attendeeUserList',
  PAGE_COUNT = 'pageCount',
  TOTAL_ATTENDEE_USER = 'totalAttendeeUser',
  ATTENDEE_USER_ONBOARDING_STATS = 'attendeeUserOnboardingStats',

}

export interface IAttendeeUserStoreState {
  [EAttendeeUserStoreAttribute.ATTENDEE_USER_LIST]: IAttendeeUser[];
  [EAttendeeUserStoreAttribute.PAGE_COUNT]: number;
  [EAttendeeUserStoreAttribute.TOTAL_ATTENDEE_USER]: number;
  [EAttendeeUserStoreAttribute.ATTENDEE_USER_ONBOARDING_STATS]: any;
}

export const attendeeUserStore = {
  state: {
    [EAttendeeUserStoreAttribute.ATTENDEE_USER_LIST]: [],
    [EAttendeeUserStoreAttribute.PAGE_COUNT]: 0,
    [EAttendeeUserStoreAttribute.TOTAL_ATTENDEE_USER]: 0,
    [EAttendeeUserStoreAttribute.ATTENDEE_USER_ONBOARDING_STATS]: {},
  },
  mutations: {
    [EAttendeeUserStoreAttribute.ATTENDEE_USER_LIST]: (
      state: IAttendeeUserStoreState,
      attendeeUserList: IAttendeeUser[],
    ) => {
      state[EAttendeeUserStoreAttribute.ATTENDEE_USER_LIST] = attendeeUserList;
    },
    [EAttendeeUserStoreAttribute.PAGE_COUNT]: (
      state: IAttendeeUserStoreState,
      pageCount: number,
    ) => {
      state[EAttendeeUserStoreAttribute.PAGE_COUNT] = pageCount;
    },
    [EAttendeeUserStoreAttribute.TOTAL_ATTENDEE_USER]: (
      state: IAttendeeUserStoreState,
      totalAttendeeUser: number,
    ) => {
      state[EAttendeeUserStoreAttribute.TOTAL_ATTENDEE_USER] = totalAttendeeUser;
    },
    [EAttendeeUserStoreAttribute.ATTENDEE_USER_ONBOARDING_STATS]: (
      state: IAttendeeUserStoreState,
      stats: any,
    ) => {
      state[EAttendeeUserStoreAttribute.ATTENDEE_USER_ONBOARDING_STATS] = stats;
    },
  },
  actions: {
    async getAttendeeUserList(context: IContext, args: IGenericListQuery): Promise<void> {
      const apiResponse = await AttendeeUserApiService.getAttendeeUserList(args);
      context.commit(EAttendeeUserStoreAttribute.ATTENDEE_USER_LIST, apiResponse.attendeeUserList);
      context.commit(EAttendeeUserStoreAttribute.PAGE_COUNT, apiResponse.pageCount);
      context.commit(EAttendeeUserStoreAttribute.TOTAL_ATTENDEE_USER, apiResponse.totalCount);
    },

    async updateAttendeeUser(
      context: IContext,
      data: {
        attendeeUserUUID: string,
        args: IAttendeeUserUpdateDto,
      },
    ): Promise<void> {
      await AttendeeUserApiService.updateAttendeeUser(
        data.attendeeUserUUID,
        data.args,
      );
    },

    async deleteAttendeeUser(
      context: IContext,
      data: {attendeeUserUUID: string},
    ): Promise<void> {
      await AttendeeUserApiService.deleteAttendeeUser(data.attendeeUserUUID);
    },

    async updateAttendeeUserMediation(
      context: IContext,
      data: {
        mediationUUID: string,
        args: IUpdateAttendeeUserMediationDto,
      },
    ): Promise<void> {
      await AttendeeUserApiService.updateAttendeeUserMediation(
        data.mediationUUID,
        data.args,
      );
    },

    async acceptAttendeeUserMediationLatestStep(
      context: IContext,
      data: {
        mediationUUID: string,
        args: IAcceptAttendeeUserMediationStepDto,
      },
    ): Promise<void> {
      await AttendeeUserApiService.acceptMediationStep(
        data.mediationUUID,
        data.args,
      );
    },

    async updateAttendeeUserPreset(
      context: IContext,
      data: {
        attendeeUserUUID: string,
        args: IUpdateAttendeeUserPresetDto,
      },
    ): Promise<void> {
      await AttendeeUserApiService.updatePreset(data.attendeeUserUUID, data.args);
    },

    async updateAttendeeUserRestPeriod(
      context: IContext,
      data: {
        attendeeUserUUID: string,
        args: IUpdateAttendeeUserRestPeriodDto,
      },
    ): Promise<void> {
      await AttendeeUserApiService.updateRestPeriod(data.attendeeUserUUID, data.args);
    },

    async getAttendeeUserOnboardingStats(
      context: IContext,
    ) {
      // const apiResponse = await AttendeeUserApiService.getAttendeeUserOnboardingStats();
      // context.commit(EAttendeeUserStoreAttribute.ATTENDEE_USER_ONBOARDING_STATS, apiResponse);
    },
  },
};
