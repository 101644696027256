export enum ERouteName {
  ATTENDEE_USER = 'Attendee user',
  DASHBOARD = 'Dashboard',
  INTERNAL_USER = 'Internal user',
  MEDIA_VIDEO = 'Media video',
  LOGIN = 'Login',
  RECOMMENDED_IOS_APP = 'Recommended IOS Apps',
  RECOMMENDED_ANDROID_APP = 'Recommended Android Apps',
  // RECOMMENDED_APP = 'Recommended Apps',
  OBSERVER_USER = 'Observer user',
  VALIDATE_CODE = 'Code validation',
  INTERNATIONALIZATION = 'Internationalization',
  APP_CATEGORY = 'App category',
  MEDIA_CATEGORY = 'Media category',
  SUBSCRIPTION = 'Subscription',
  GHOST = 'Ghost',
  ADMIN_APP_PARAMETERS = 'App parameters',
  ADMIN_APP_DEV = 'App admin dev',
  ADMIN_APP_CSV = 'App admin csv',
  SCREEN_TIME_BY_SUBSCRIPTION = 'By subscription',
  DEVICE = 'Device',
  ONBOARDING_STATS = 'Onboarding stats',
  ADMIN_APP_CATEGORY = 'App category',
}

export enum ERoutePathPrefix {
  RECOMMENDED_APP = '/recommended-app/',
  MEDIA = '/media/',
  USER = '/user/',
  SUPER_ADMIN = '/super-admin/',
  SUBSCRIPTION = '/subscription/',
  SCREEN_TIME = '/screen-time/',
  DEVICE = '/device/',
  STATS = '/stats/',
}

export enum ERouteParameter {
  DEVICE_PLATFORM = 'device_platform',
}
