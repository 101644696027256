<template lang="pug">
div
  // Boîte de dialogue de confirmation
  v-dialog(
    :value="confirmDialog"
    @input="$emit('close-confirm')"
    max-width="400"
  )
    v-card
      v-card-title Confirmation
      v-card-text Êtes-vous sûr de vouloir sauvegarder ce prompt?
      v-card-actions
        v-spacer
        v-btn(
          text
          @click="$emit('close-confirm')"
        ) Annuler
        v-btn(
          color="success"
          @click="$emit('save-prompt')"
        ) Confirmer

  // Modal de gestion des prompts
  v-dialog(
    :value="promptsManagerDialog"
    @input="$emit('close-manager')"
    max-width="600"
  )
    v-card
      v-card-title Gestion des prompts
      v-card-text
        v-list
          v-list-item(
            v-for="prompt in promptList"
            :key="prompt.id"
          )
            v-list-item-content
              v-list-item-title
                v-icon(v-if="prompt.id === activePromptId" color="success" small left) mdi-check-circle
                | {{ prompt.displayText }}
            v-list-item-action
              v-btn(
                icon
                color="error"
                @click="$emit('confirm-delete', prompt.id)"
                :disabled="prompt.id === activePromptId"
              )
                v-icon mdi-delete
      v-card-actions
        v-spacer
        v-btn(
          text
          @click="$emit('close-manager')"
        ) Fermer

  // Boîte de dialogue de confirmation de suppression
  v-dialog(
    :value="confirmDeleteDialog"
    @input="$emit('close-delete')"
    max-width="400"
  )
    v-card
      v-card-title Confirmation de suppression
      v-card-text Êtes-vous sûr de vouloir supprimer ce prompt? Cette action est irréversible.
      v-card-actions
        v-spacer
        v-btn(
          text
          @click="$emit('close-delete')"
        ) Annuler
        v-btn(
          color="error"
          @click="$emit('delete-prompt')"
          :loading="deleteLoading"
        ) Supprimer
</template>

<script>
export default {
  name: 'PromptDialogs',
  props: {
    confirmDialog: {
      type: Boolean,
      default: false
    },
    promptsManagerDialog: {
      type: Boolean,
      default: false
    },
    confirmDeleteDialog: {
      type: Boolean,
      default: false
    },
    promptList: {
      type: Array,
      required: true
    },
    activePromptId: {
      type: [String, Number, null],
      default: null
    },
    deleteLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script> 