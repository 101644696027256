import Vue from 'vue';
import InternationalizationView from '@/views/super-admin/internationalization-view.vue';
import VueRouter, {Route, RouteConfig} from 'vue-router';
import {isUndef} from '@/service/static/control.service';
import AttendeeUserView from '@/views/user/attendee-user-view.vue';
import ObserverUserView from '@/views/user/observer-user-view.vue';
import InternalUserView from '@/views/user/internal-user-view.vue';
import SubscriptionView from '@/views/subscription/subscription-view.vue';
import AppCategoryView from '@/views/recommended-app/app-category-view.vue';
import MediaCategoryView from '@/views/media/media-category-view.vue';
import GhostView from '@/views/super-admin/ghost-view.vue';
import AdminAppParametersView from '@/views/super-admin/admin-app-parameters-view.vue';
import ScreenTimeStatisticsView from '@/views/screen-time/screen-time-statistics-view.vue';
import ScreenTimeBySubscriptionView from '@/views/screen-time/screen-time-by-subscription-view.vue';
import DeviceView from '@/views/device/device-view.vue';
import OnboardingStatsView from '@/views/stats/onboarding-stats-view.vue';
import store from '@/store';
import AdminDevHot from '@/views/super-admin/admin-dev-hot.vue';
import {ERouteName, ERoutePathPrefix} from './router.enum';
import MediaVideoView from '../views/media/youtube-media-view.vue';
import LoginView from '../views/authentication/login-view.vue';
import ValidateCodeView from '../views/authentication/validate-code-view.vue';
import AdminCsv from '@/views/super-admin/admin-csv.vue';
import RecommendedAndroidAppView from '@/views/recommended-app/recommended-android-app-view.vue';
import RecommendedIosAppView from '@/views/recommended-app/recommended-ios-app-view.vue';
import AdminAppCategories from '@/views/super-admin/admin-app-categories/admin-app-categories.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [

  // Authentication
  {path: '/login', name: ERouteName.LOGIN, component: LoginView},
  {path: '/validate-code', name: ERouteName.VALIDATE_CODE, component: ValidateCodeView},
  {path: '/logout', name: 'Logout', redirect: '/login'},

  // Dashboard
  {path: '/', name: ERouteName.DASHBOARD, component: ScreenTimeStatisticsView},

  // Screen time
  {
    path: `${ERoutePathPrefix.SCREEN_TIME}by-subscription`,
    name: ERouteName.SCREEN_TIME_BY_SUBSCRIPTION,
    component: ScreenTimeBySubscriptionView,
  },

  // Stats
  {
    path: `${ERoutePathPrefix.STATS}onboarding`,
    name: ERouteName.ONBOARDING_STATS,
    component: OnboardingStatsView,
  },

  // Media
  {
    path: `${ERoutePathPrefix.MEDIA}video`,
    name: ERouteName.MEDIA_VIDEO,
    component: MediaVideoView,
  },
  {
    path: `${ERoutePathPrefix.MEDIA}category`,
    name: ERouteName.MEDIA_CATEGORY,
    component: MediaCategoryView,
  },

  // Recommended Apps
  {
    path: `${ERoutePathPrefix.RECOMMENDED_APP}ios`,
    name: ERouteName.RECOMMENDED_IOS_APP,
    component: RecommendedIosAppView,
  },
  {
    path: `${ERoutePathPrefix.RECOMMENDED_APP}android`,
    name: ERouteName.RECOMMENDED_ANDROID_APP,
    component: RecommendedAndroidAppView,
  },
  // {
  //   path: `${ERoutePathPrefix.RECOMMENDED_APP}:${ERouteParameter.DEVICE_PLATFORM}`,
  //   name: ERouteName.RECOMMENDED_APP,
  //   component: RecommendedAppView,
  // },
  {
    path: `${ERoutePathPrefix.RECOMMENDED_APP}category`,
    name: ERouteName.APP_CATEGORY,
    component: AppCategoryView,
  },

  // Users
  {
    path: `${ERoutePathPrefix.USER}attendee-user`,
    name: ERouteName.ATTENDEE_USER,
    component: AttendeeUserView,
  },
  {
    path: `${ERoutePathPrefix.USER}internal-user`,
    name: ERouteName.INTERNAL_USER,
    component: InternalUserView,
  },
  {
    path: `${ERoutePathPrefix.USER}observer-user`,
    name: ERouteName.OBSERVER_USER,
    component: ObserverUserView,
  },

  // Subscriptions
  {
    path: `${ERoutePathPrefix.SUBSCRIPTION}subscription`,
    name: ERouteName.SUBSCRIPTION,
    component: SubscriptionView,
  },

  // Devices
  {
    path: `${ERoutePathPrefix.DEVICE}device`,
    name: ERouteName.DEVICE,
    component: DeviceView,
  },

  // Super admin
  {
    path: `${ERoutePathPrefix.SUPER_ADMIN}internationalization`,
    name: ERouteName.INTERNATIONALIZATION,
    component: InternationalizationView,
  },
  {
    path: `${ERoutePathPrefix.SUPER_ADMIN}ghost`,
    name: ERouteName.GHOST,
    component: GhostView,
  },
  {
    path: `${ERoutePathPrefix.SUPER_ADMIN}admin-app-parameters`,
    name: ERouteName.ADMIN_APP_PARAMETERS,
    component: AdminAppParametersView,
  },
  {
    path: `${ERoutePathPrefix.SUPER_ADMIN}admin-dev`,
    name: ERouteName.ADMIN_APP_DEV,
    component: AdminDevHot,
  },
  {
    path: `${ERoutePathPrefix.SUPER_ADMIN}admin-csv`,
    name: ERouteName.ADMIN_APP_CSV,
    component: AdminCsv,
  },
  {
    path: `${ERoutePathPrefix.SUPER_ADMIN}admin-app-category`,
    name: ERouteName.ADMIN_APP_CATEGORY,
    component: AdminAppCategories,
  },

  // {
  // path: '/about',
  // name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () => import(/* webpackChunkName: "about" */ '../views/about-view.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: '/', // process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to: Route, from: Route, next: any) => {
  if (to.path === '/login') {
    await store.dispatch('logout');
    next();
  }

  const notNeedsAuthPathList: string[] = ['/validate-code', '/login'];

  if (notNeedsAuthPathList.indexOf(to.path) !== -1) {
    next();
  } else {
    await store.dispatch('getMe');

    if (isUndef(store.getters.user)) {
      next('/login');
    } else {
      next();
    }
  }
});

export default router;
