<template lang="pug">
v-container.fill-height.pa-0(fluid)
  v-row(no-gutters style="height: 100%")
    v-col(cols="7" class="d-flex flex-column")
      prompt-editor(
        :prompt="prompt"
        :selected-prompt-id="selectedPromptId"
        :prompt-list="promptList"
        :prompts-loading="promptsLoading"
        :active-prompt-id="activePromptId"
        :is-active-prompt="isActivePrompt"
        :is-edit-mode="isEditMode"
        :save-loading="saveLoading"
        :set-active-loading="setActiveLoading"
        @update:prompt="updatePrompt"
        @prompt-selected="onPromptSelected"
        @open-prompts-manager="openPromptsManager"
        @create-from-template="createFromTemplate"
        @confirm-save="confirmSave"
        @set-active-prompt="setActivePrompt"
      )
    
    v-col(cols="5" class="d-flex flex-column" style="height: 100%")
      prompt-test-result(
        :test-result="testResult"
        :test-loading="testLoading"
        :is-saved-result="isLoadedFromSaved"
        @test-prompt="testPrompt"
      )
  
  // Boîtes de dialogue
  prompt-dialogs(
    :confirm-dialog="confirmDialog"
    :prompts-manager-dialog="promptsManagerDialog"
    :confirm-delete-dialog="confirmDeleteDialog"
    :prompt-list="promptList"
    :active-prompt-id="activePromptId"
    :delete-loading="deleteLoading"
    @close-confirm="confirmDialog = false"
    @save-prompt="savePrompt"
    @close-manager="promptsManagerDialog = false"
    @confirm-delete="confirmDeletePrompt"
    @close-delete="confirmDeleteDialog = false"
    @delete-prompt="deletePrompt"
  )
</template>

<script>
import { AdminAndroidAppCategoriesApiService } from '@/service/api/adminAndroidAppCategories/admin.android.app.categories.api.service';
import PromptEditor from './components/PromptEditor.vue';
import PromptTestResult from './components/PromptTestResult.vue';
import PromptDialogs from './components/PromptDialogs.vue';

// Clé pour le stockage local des résultats de test
const TEST_RESULTS_STORAGE_KEY = 'admin-app-categories-test-results';

export default {
  name: 'AdminAppCategories',
  components: {
    PromptEditor,
    PromptTestResult,
    PromptDialogs
  },
  data() {
    return {
      prompt: '',
      testResult: null,
      testLoading: false,
      saveLoading: false,
      setActiveLoading: false,
      confirmDialog: false,
      promptList: [],
      promptsLoading: false,
      selectedPromptId: null,
      currentPrompt: null,
      activePromptId: null,
      promptsManagerDialog: false,
      confirmDeleteDialog: false,
      promptToDeleteId: null,
      deleteLoading: false,
      modifiedPrompts: {},
      savedTestResults: {}, // Stockage des résultats par ID de prompt
      isLoadedFromSaved: false // Indique si le résultat actuel est chargé depuis le stockage
    }
  },
  async created() {
    // Charger d'abord les résultats sauvegardés
    this.loadSavedTestResults();
    
    // Puis charger les prompts
    await this.loadPrompts();
    
    // Si un prompt est sélectionné par défaut, charger son résultat
    if (this.selectedPromptId) {
      this.loadTestResultForCurrentPrompt();
    }
  },
  mounted() {
    // Vérifier à nouveau après le montage complet du composant
    this.$nextTick(() => {
      if (this.selectedPromptId) {
        this.loadTestResultForCurrentPrompt();
      }
    });
  },
  computed: {
    isEditMode() {
      return this.selectedPromptId !== null && this.selectedPromptId !== undefined;
    },
    isActivePrompt() {
      return this.selectedPromptId === this.activePromptId;
    }
  },
  methods: {
    async loadPrompts() {
      this.promptsLoading = true;
      try {
        const prompts = await AdminAndroidAppCategoriesApiService.getAndroidApplicationPromptList();
        
        // Vérifier si prompts est un tableau et s'il contient des éléments
        if (!Array.isArray(prompts) || prompts.length === 0) {
          this.promptList = [];
          return;
        }
        
        // Trouver le prompt actif s'il existe
        const activePrompt = prompts.find(p => p.active === true);
        if (activePrompt) {
          this.activePromptId = activePrompt.id;
          
          // Sélectionner automatiquement le prompt actif
          if (!this.selectedPromptId) {
            this.selectedPromptId = activePrompt.id;
            this.prompt = activePrompt.prompt || '';
            this.currentPrompt = activePrompt;
            
            // Charger le résultat de test pour ce prompt
            this.$nextTick(() => {
              this.loadTestResultForCurrentPrompt();
            });
          }
        } else {
          this.activePromptId = null;
        }
        
        // Traiter les prompts
        this.promptList = prompts.map(p => {
          if (!p || !p.id || !p.updatedAt) {
            return null;
          }
          
          const date = new Date(p.updatedAt);
          const formattedDate = `${date.toLocaleDateString()} ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
          
          return {
            id: p.id,
            prompt: p.prompt || '',
            updatedAt: p.updatedAt,
            createdAt: p.createdAt,
            isActive: p.active,
            displayText: `${p.id} - ${formattedDate}`
          };
        }).filter(p => p !== null);
        
        // Si aucun prompt n'est sélectionné, sélectionner l'actif ou le plus récent
        if (!this.selectedPromptId && this.promptList.length > 0) {
          if (this.activePromptId) {
            this.selectedPromptId = this.activePromptId;
          } else {
            const mostRecent = [...this.promptList].sort((a, b) => 
              new Date(b.updatedAt) - new Date(a.updatedAt)
            )[0];
            this.selectedPromptId = mostRecent.id;
          }
          await this.onPromptSelected();
        } else if (this.selectedPromptId) {
          // Recharger le prompt sélectionné
          await this.onPromptSelected();
        }
      } catch (error) {
        console.error('Erreur lors du chargement des prompts:', error);
        if (this.$toast) {
          this.$toast.error('Erreur lors du chargement des prompts');
        }
        this.promptList = [];
      } finally {
        this.promptsLoading = false;
      }
    },
    
    async onPromptSelected(newPromptId) {
      console.log('onPromptSelected appelé avec:', newPromptId);
      
      // Si un nouveau promptId est fourni, le mettre à jour
      if (newPromptId !== undefined) {
        this.selectedPromptId = newPromptId;
      }
      
      console.log('Prompt sélectionné après mise à jour:', this.selectedPromptId);
      
      if (!this.selectedPromptId) {
        this.prompt = '';
        this.currentPrompt = null;
        return;
      }
      
      // Si selectedPromptId est un objet (à cause de return-object)
      const promptId = typeof this.selectedPromptId === 'object' 
        ? this.selectedPromptId.id 
        : this.selectedPromptId;
      
      console.log('Recherche du prompt avec ID:', promptId);
      
      // Vérifier si nous avons une version modifiée en cache
      if (this.modifiedPrompts[promptId]) {
        console.log('Utilisation de la version modifiée du cache');
        this.prompt = this.modifiedPrompts[promptId];
      } else {
        // Sinon, charger depuis la liste
        const selectedPrompt = this.promptList.find(p => p.id === promptId);
        console.log('Prompt trouvé dans la liste:', selectedPrompt);
        
        if (selectedPrompt) {
          console.log('Chargement du contenu du prompt:', selectedPrompt.prompt);
          this.prompt = selectedPrompt.prompt || '';
        } else {
          console.log('Aucun prompt trouvé, réinitialisation');
          this.prompt = '';
        }
      }
      
      // Mettre à jour le prompt courant
      this.currentPrompt = this.promptList.find(p => p.id === promptId);
      console.log('Prompt courant mis à jour:', this.currentPrompt);
      
      // Si un prompt est sélectionné, charger son résultat de test sauvegardé
      if (this.selectedPromptId) {
        // Utiliser $nextTick pour s'assurer que les données sont à jour
        this.$nextTick(() => {
          this.loadTestResultForCurrentPrompt();
        });
      } else {
        // Si aucun prompt n'est sélectionné, effacer le résultat
        this.testResult = null;
        this.isLoadedFromSaved = false;
      }
    },
    
    // Méthode appelée lorsque le contenu du prompt est modifié
    updatePrompt(value) {
      this.prompt = value;
      
      // Si nous sommes en mode édition, stocker la version modifiée
      if (this.selectedPromptId) {
        this.modifiedPrompts[this.selectedPromptId] = value;
      } else {
        // Si nous sommes en mode création, réinitialiser le résultat de test
        // car le prompt a été modifié et n'est plus lié au résultat précédent
        this.testResult = null;
        this.isLoadedFromSaved = false;
      }
    },
    
    async setActivePrompt() {
      if (!this.selectedPromptId) return;
      
      this.setActiveLoading = true;
      try {
        await AdminAndroidAppCategoriesApiService.setActiveAndroidApplicationPrompt({
          id: this.selectedPromptId
        });
        
        if (this.$toast) {
          this.$toast.success('Prompt défini comme actif avec succès');
        }
        
        // Mettre à jour l'ID du prompt actif
        this.activePromptId = this.selectedPromptId;
        
        // Recharger la liste pour mettre à jour les indicateurs visuels
        await this.loadPrompts();
      } catch (error) {
        console.error('Erreur lors de la définition du prompt actif:', error);
        if (this.$toast) {
          this.$toast.error('Erreur lors de la définition du prompt actif');
        }
      } finally {
        this.setActiveLoading = false;
      }
    },
    
    async testPrompt() {
      this.testLoading = true;
      this.isLoadedFromSaved = false; // Nouveau test, pas chargé depuis le stockage
      
      try {
        const response = await AdminAndroidAppCategoriesApiService
        .testAndroidApplicationPrompt({
          prompt: this.prompt
        });
        
        // S'assurer que la réponse a le bon format
        if (response && typeof response === 'object') {
          this.testResult = response;
        } else {
          // Fallback si la réponse n'a pas le format attendu
          this.testResult = {
            results: [],
            rawResponse: response
          };
        }
        
        // Sauvegarder automatiquement le résultat pour ce prompt
        if (this.selectedPromptId) {
          this.saveTestResultForCurrentPrompt();
        }
      } catch (error) {
        console.error('Erreur lors du test du prompt:', error);
        if (this.$toast) {
          this.$toast.error('Erreur lors du test du prompt');
        }
        // Afficher l'erreur dans les résultats
        this.testResult = {
          error: error.message || 'Une erreur est survenue lors du test',
          results: []
        };
      } finally {
        this.testLoading = false;
      }
    },
    
    createFromTemplate() {
      // Sauvegarder le contenu actuel dans le cache si nécessaire
      if (this.selectedPromptId) {
        this.modifiedPrompts[this.selectedPromptId] = this.prompt;
      }
      
      // Créer un nouveau prompt à partir du modèle
      const templateContent = this.prompt;
      this.selectedPromptId = null;
      this.currentPrompt = null;
      this.prompt = templateContent;
      
      // Réinitialiser le résultat de test
      this.testResult = null;
      this.isLoadedFromSaved = false;
    },
    
    confirmSave() {
      // Réinitialiser le résultat si on est en mode création
      if (!this.selectedPromptId) {
        this.testResult = null;
        this.isLoadedFromSaved = false;
      }
      this.confirmDialog = true;
    },
    
    async savePrompt() {
      this.confirmDialog = false;
      this.saveLoading = true;
      try {
        if (this.selectedPromptId) {
          // Mise à jour d'un prompt existant
          await AdminAndroidAppCategoriesApiService.updateAndroidApplicationPrompt({
            id: this.selectedPromptId,
            prompt: this.prompt
          });
          
          if (this.$toast) {
            this.$toast.success('Prompt mis à jour avec succès');
          }
          
          // Supprimer du cache des modifications
          delete this.modifiedPrompts[this.selectedPromptId];
          
          // Recharger la liste des prompts après la mise à jour
          await this.loadPrompts();
        } else {
          // Création d'un nouveau prompt
          const newPrompt = await AdminAndroidAppCategoriesApiService.createAndroidApplicationPrompt({
            prompt: this.prompt
          });
          
          if (this.$toast) {
            this.$toast.success('Nouveau prompt créé avec succès');
          }
          
          // Réinitialiser le résultat de test pour le nouveau prompt
          this.testResult = null;
          this.isLoadedFromSaved = false;
          
          // Recharger la liste des prompts pour inclure le nouveau
          await this.loadPrompts();
          
          // Sélectionner le nouveau prompt
          if (newPrompt && newPrompt.id) {
            this.selectedPromptId = newPrompt.id;
            // Supprimer du cache car c'est maintenant un prompt sauvegardé
            delete this.modifiedPrompts[newPrompt.id];
            
            // S'assurer que le résultat est toujours réinitialisé
            this.$nextTick(() => {
              this.testResult = null;
              this.isLoadedFromSaved = false;
            });
          }
        }
      } catch (error) {
        console.error('Erreur lors de la sauvegarde du prompt:', error);
        if (this.$toast) {
          this.$toast.error('Erreur lors de la sauvegarde du prompt');
        }
      } finally {
        this.saveLoading = false;
      }
    },
    
    openPromptsManager() {
      this.promptsManagerDialog = true;
    },
    
    confirmDeletePrompt(promptId) {
      this.promptToDeleteId = promptId;
      this.confirmDeleteDialog = true;
    },
    
    async deletePrompt() {
      if (!this.promptToDeleteId) {
        this.confirmDeleteDialog = false;
        return;
      }
      
      this.deleteLoading = true;
      try {
        await AdminAndroidAppCategoriesApiService.deleteAndroidApplicationPrompt(this.promptToDeleteId);
        
        if (this.$toast) {
          this.$toast.success('Prompt supprimé avec succès');
        }
        
        // Si le prompt supprimé était celui sélectionné, réinitialiser
        if (this.selectedPromptId === this.promptToDeleteId) {
          this.selectedPromptId = null;
          this.prompt = '';
          this.currentPrompt = null;
        }
        
        // Supprimer du cache des modifications
        delete this.modifiedPrompts[this.promptToDeleteId];
        
        // Recharger la liste des prompts
        await this.loadPrompts();
        
        this.confirmDeleteDialog = false;
        this.promptToDeleteId = null;
      } catch (error) {
        console.error('Erreur lors de la suppression du prompt:', error);
        if (this.$toast) {
          this.$toast.error('Erreur lors de la suppression du prompt');
        }
      } finally {
        this.deleteLoading = false;
      }
    },
    
    // Méthodes pour la gestion des résultats sauvegardés
    loadSavedTestResults() {
      try {
        const savedResults = localStorage.getItem(TEST_RESULTS_STORAGE_KEY);
        if (savedResults) {
          this.savedTestResults = JSON.parse(savedResults);
        }
      } catch (error) {
        console.error('Erreur lors du chargement des résultats sauvegardés:', error);
        this.savedTestResults = {};
      }
    },
    
    saveTestResultForCurrentPrompt() {
      if (!this.testResult || !this.selectedPromptId) return;
      
      try {
        // Sauvegarder le résultat pour ce prompt
        this.savedTestResults[this.selectedPromptId] = {
          result: this.testResult,
          timestamp: new Date().toISOString()
        };
        
        // Sauvegarder dans le localStorage
        localStorage.setItem(TEST_RESULTS_STORAGE_KEY, JSON.stringify(this.savedTestResults));
        
        console.log(`Résultat de test sauvegardé pour le prompt ${this.selectedPromptId}`);
      } catch (error) {
        console.error('Erreur lors de la sauvegarde du résultat:', error);
      }
    },
    
    loadTestResultForCurrentPrompt() {
      if (!this.selectedPromptId) {
        // Pas de prompt sélectionné
        this.testResult = null;
        this.isLoadedFromSaved = false;
        return;
      }
      
      if (!this.savedTestResults || !this.savedTestResults[this.selectedPromptId]) {
        // Pas de résultat sauvegardé pour ce prompt
        this.testResult = null;
        this.isLoadedFromSaved = false;
        return;
      }
      
      try {
        // Charger le résultat sauvegardé
        const savedData = this.savedTestResults[this.selectedPromptId];
        this.testResult = savedData.result;
        this.isLoadedFromSaved = true; // Indiquer que le résultat est chargé depuis le stockage
        
        console.log(`Résultat de test chargé pour le prompt ${this.selectedPromptId} (sauvegardé le ${new Date(savedData.timestamp).toLocaleString()})`);
      } catch (error) {
        console.error('Erreur lors du chargement du résultat sauvegardé:', error);
        this.testResult = null;
        this.isLoadedFromSaved = false;
      }
    }
  }
}
</script>

<style scoped>
/* Assurer que les colonnes prennent toute la hauteur */
.v-row {
  height: 100%;
}

.v-col {
  height: 100%;
}

.json-result {
  background-color: #ffffff;
  color: #000000;
  padding: 16px;
  border-radius: 4px;
  font-family: monospace;
  white-space: pre-wrap;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.result-container {
  display: flex;
  overflow: auto !important;
  max-height: calc(100vh - 200px);
}

.prompt-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.prompt-editor :deep(.v-input__control) {
  font-family: monospace;
  line-height: 1.5;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.prompt-editor :deep(.v-input__slot) {
  flex-grow: 1;
  height: 100%;
  display: flex;
}

.prompt-editor :deep(.v-text-field__slot) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.prompt-editor :deep(.v-text-field__slot textarea) {
  white-space: pre-wrap;
  font-family: monospace;
  line-height: 1.5;
  flex-grow: 1;
  height: 100% !important;
  max-height: none !important;
  overflow-y: auto !important;
}
</style>