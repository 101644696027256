import {ApiService} from '@/service/api/api.service';
import {IGetAdminAndroidAppCategoriesTestResponseDto} from './admin.android.app.categories.api.interface';

// Centralisation des endpoints dans un objet pour faciliter la maintenance
const ENDPOINTS = {
  LIST: '/api/admin/android-application-prompt/list',
  SET_ACTIVE: '/api/admin/android-application-prompt/set-active',
  CREATE: '/api/admin/android-application-prompt/create',
  TEST: '/api/admin/android-application-prompt/test',
  UPDATE: '/api/admin/android-application-prompt/update',
  DELETE: '/api/admin/android-application-prompt/delete'
};

// Interfaces
export interface IAndroidApplicationPrompt {
  id: number;
  prompt: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface ISetActivePromptDto {
  id: number;
}

export interface ICreatePromptDto {
  prompt: string;
}

export interface ITestPromptDto {
  prompt: string;
}

export interface IUpdatePromptDto {
  id: number;
  prompt: string;
}

// Type pour les résultats de test (à définir selon les besoins réels)
export interface ITestPromptResult {
  success: boolean;
  categories?: string[];
  error?: string;
}

export class AdminAndroidAppCategoriesApiService {
  // Méthodes simplifiées sans await redondant
  public static getAndroidApplicationPromptList(): Promise<IAndroidApplicationPrompt[]> {
    return ApiService.get(ENDPOINTS.LIST);
  }

  public static setActiveAndroidApplicationPrompt(
    dto: ISetActivePromptDto
  ): Promise<IAndroidApplicationPrompt> {
    return ApiService.put(ENDPOINTS.SET_ACTIVE, dto);
  }

  public static createAndroidApplicationPrompt(
    dto: ICreatePromptDto
  ): Promise<IAndroidApplicationPrompt> {
    return ApiService.post(ENDPOINTS.CREATE, dto);
  }

  public static testAndroidApplicationPrompt(
    dto: ITestPromptDto
  ): Promise<IGetAdminAndroidAppCategoriesTestResponseDto> {
    return ApiService.post(ENDPOINTS.TEST, dto);
  }

  public static updateAndroidApplicationPrompt(
    dto: IUpdatePromptDto
  ): Promise<IAndroidApplicationPrompt> {
    return ApiService.put(ENDPOINTS.UPDATE, dto);
  }

  public static deleteAndroidApplicationPrompt(id: number): Promise<void> {
    return ApiService.delete(`${ENDPOINTS.DELETE}/${id}`);
  }
}
